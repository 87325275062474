import React from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineCheck } from "react-icons/ai";
import styles from '../styles.module.scss'
import Button from '../../../components/Button';
import { createComissionApprove } from '../../../actions/common';


export default function ExpertComissionBlock ({ requestId }) {
  
  const { user_id } = useSelector(state => state.auth)
  const { list, values } = useSelector(state => state.common.comission)
  const dispatch = useDispatch()

  const approvedUsers = values?.map(_ => _.mid) || []
  const alreadyApproved = approvedUsers.includes(user_id)

  const includeExpert = list?.map(_ => _.MID).includes(user_id)

  const renderRow = (expert, index) => {
    const {MID, LastName, FirstName, Patronymic} = expert
    const checked = approvedUsers.includes(MID)
    const fio = `${LastName} ${FirstName} ${Patronymic}`
    return (
      <tr>
        <td>{fio}</td>
        <td>
          {checked && <AiOutlineCheck size={22} color='#10d310' />}
        </td>
      </tr>
    )
  }

  const handleApprove = () => {
    const param = { status: 1, is_expert: true}
    dispatch(createComissionApprove(requestId, param))
  }

  return (
    <div className={styles.comissionBlock}>
     
     <Table id='comissionTable'>
        <thead>
          <tr>
            <th>ФИО</th>
            <th width={'100px'}>Подпись</th>
          </tr>
        </thead>
        <tbody>
          {list?.map(renderRow)}
        </tbody>
      </Table>

      <Button
        disabled={!list?.length || !includeExpert || alreadyApproved}
        onClick={handleApprove}
        label='Проверено'
      />

    </div>
  )
}