import * as types from "../actions/types";

const initialState = {
    bank: null,
    count: 0,
    loading: false,
};


export default function bank (state = initialState, action) {
  switch (action.type) {
    case types.SET_BANK:
      return {
        ...state,
        bank: action.payload,
      };
      case types.SET_BANK_COUNT:
        return {
          ...state,
          count: action.payload
        };
    case types.SET_BANK_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}