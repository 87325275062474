import React from 'react'
import styles from './Confirmation.module.css'
import Button from '../Button'

export default function Confirmation({
    confirmation = "",
    title = "Вы уверены что хотите удалить",
    acceptConfirmation = () => {},
    rejectConfirmation = () => {}    
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.confirmation}>
      <div className={styles.textBlock}>
        {title}
        {!!confirmation && <span>{confirmation}</span>}
      </div>
      <div className={styles.buttons}>
        <Button label='Да' onClick={() => acceptConfirmation(confirmation)}/>
        <Button label='Нет' onClick={() => rejectConfirmation()}/>
      </div> 
    </div>
    </div>
  )
}
