
export const getYearsOptions = (count, reverse) => {
  const year = new Date().getFullYear() 
  const min = reverse ? year - count : year
  const max = reverse ? year : year + count
  const years = []
  for (var i = min; i <= max; i++) {
    years.push(<option key={i}>{i}</option>)
  }
  return reverse ? years.reverse() : years
}

export const getOrganizationOptions = (orgArray) => orgArray.map(org => <option key={org.idOrganization} value={org.idOrganization}>{org.OrgName}</option>) 

export const getOptions = (array) => {
    const _array = array || []
    return _array.map(item => {
        const value = item.id || item.value
        return <option key={value} value={value}>{item.name}</option>
    })
}
