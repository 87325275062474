import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_REPORTS_LOADING, SET_REPORTS_LIST, SET_REPORT_DATA } from "./types";


export const getReportsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_REPORTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/reports`, {
      method: "GET",
      ...buildDefaultHeaders(getState)
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REPORTS_LIST, payload });
    }
    dispatch({ type: SET_REPORTS_LOADING, payload: false });
  }
};

export const getReportData = (reportName) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_REPORTS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/reports/${reportName}`, {
      method: "GET",
      ...buildDefaultHeaders(getState)
    });
    if (response.ok) {
      const payload = await response.json();
      await dispatch({ type: SET_REPORT_DATA, payload, reportName });
    }
    dispatch({ type: SET_REPORTS_LOADING, payload: false });
  }
};
