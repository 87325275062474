import React from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css'

export default function GoBack () {
  
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }

  return (
    <i onClick={goHome} className={styles.backIcon}>
      <FaArrowLeft />
    </i>
  )
}
