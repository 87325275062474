export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const SIGNED_OUT = 'SIGNED_OUT'
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO'
export const SET_AUTH_USER_PHOTO = 'SET_AUTH_USER_PHOTO'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'

export const REMOVE_COMMON_REQUEST_DATA = 'REMOVE_COMMON_REQUEST_DATA'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_COMISSION_DATES = 'SET_COMISSION_DATES'
export const SET_SPECIALIZATIONS = 'SET_SPECIALIZATIONS'
export const SET_REQUESTS_LIST = 'SET_REQUESTS_LIST'
export const SET_CURRENT_REQUEST = 'SET_CURRENT_REQUEST'
export const SET_REQUESTS_PARTS = 'SET_REQUESTS_PARTS'
export const SET_COMMON_CRITERIONS = 'SET_COMMON_CRITERIONS'
export const SET_LESSONS = 'SET_LESSONS'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_TOPICS = 'SET_TOPICS'
export const SET_FILES_LIST = 'SET_FILES_LIST'
export const SET_COMMON_LOADING = 'SET_COMMON_LOADING'
export const SET_COMMISSION_LIST = 'SET_COMMISSION_LIST'
export const SET_COMMISSION_VALUES = 'SET_COMMISSION_VALUES'
export const SET_REQUEST_AWARDS = 'SET_REQUEST_AWARDS'

export const SET_TEACHER_LOADING = 'SET_TEACHER_LOADING'
export const SET_TEACHER_FORM_LOADING = 'SET_TEACHER_FORM_LOADING'

export const SET_EXPERT_FORM = 'SET_EXPERT_FORM'
export const SET_EXPERT_LOADING = 'SET_EXPERT_LOADING'

export const SET_BANK = 'SET_BANK'
export const SET_BANK_LOADING = 'SET_BANK_LOADING'
export const SET_BANK_COUNT = 'SET_BANK_COUNT'

export const SET_REPORTS_LIST = 'SET_REPORTS_LIST'
export const SET_REPORT_DATA = 'SET_REPORT_DATA'
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING'