import React, { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineCheck, AiOutlineClose, AiOutlineDash} from "react-icons/ai";
import styles from '../styles.module.scss'
import Button from '../../../components/Button';
import { createComissionApprove } from '../../../actions/common';

const statuses = [
  {value: 1, name: 'За'},
  {value: 2, name: 'Против'},
  {value: 3, name: 'Воздержался'}
]

export default function ComissionBlock ({ requestId }) {
  
  const { edu_expert, user_id } = useSelector(state => state.auth)
  const { list, values } = useSelector(state => state.common.comission)
  const dispatch = useDispatch()

  const [status, setStatus] = useState('')

  useEffect(() => {
    const approveData = values?.find(({mid}) => mid === user_id)
    setStatus(approveData?.status || '')
  }, [values])
  

  const renderRow = (expert, index) => {
    const {MID, expert_fio, position} = expert
    const status = values?.find(({mid}) => mid === MID)?.status
    return (
      <tr key={index}>
        <td>{expert_fio}</td>
        <td>{position}</td>
        <td>
          {status == 1
            ? <AiOutlineCheck size={22} color='#10d310'/>
            : status == 2
              ? <AiOutlineClose size={22} color='red'/>
              : status == 3
                ? <AiOutlineDash size={22} color='#ff8400'/>
                : ''
          }
        </td>
      </tr>
    )
  }

  const handleApprove = () => {
    const param = { status }
    dispatch(createComissionApprove(requestId, param))
  }

  const alreadyApproved = values?.find(({mid}) => mid === user_id)


  return (
    <div className={styles.comissionBlock}>
      <Table id='comissionTable'>
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Должность</th>
            <th>Подпись</th>
          </tr>
        </thead>
        <tbody>
          {list?.length
            ? list.map(renderRow)
            : <tr className={styles.noDataRow}>
                <td colSpan={3}>Данные отсутствуют</td>
              </tr>
          }
        </tbody>
      </Table >
      {edu_expert &&
        <div className={styles.approveBlock}>
          <Form.Select
            value={status}
            style={{width: '200px'}}
            disabled={alreadyApproved || !list?.length}
            onChange={e => setStatus(e.target.value)}
          >
            <option value=''>Выбрать</option>
            {statuses.map(({name, value}) => <option value={value}>{name}</option>)}
          </Form.Select>
          <Button
            disabled={alreadyApproved || !list?.length || !status}
            onClick={handleApprove}
            label='Подписать'
          />
        </div>
      }
    </div>
  )
}
