import React from 'react'
import { FaFile } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import styles from './FileLabel.module.css'
import pdfIcon from '../../assets/images/icon-pdf.png'


export default function FileLabel({
    fileName,
    imageMode,
    pdfMode,
    fileLink,
    onDelete,
    onClick,
    withoutLabel
}) {
  
  
  const deleteFile = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete()
  }

  const fileClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick) {
      onClick()
    } else if (fileLink) {
      const link = document.createElement('a')
      link.setAttribute('href', fileLink)
      link.setAttribute('target', 'blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.setAttribute('download', fileName)
      link.click()
    }
  }

  const icon = imageMode
    ? <div><img src={fileLink} alt="o_O"/></div>
    : (pdfMode || fileName?.includes('pdf'))
      ? <div><img src={pdfIcon} alt="o_O"/></div>
      : <i><FaFile className={styles.fileIcon}/></i>

  return (
    <div
      onClick={fileClick}
      className={styles.fileBlock}
    >
      {onDelete && <IoMdClose className={styles.deleteIcon} onClick={deleteFile}/>}
      {icon}
      {!withoutLabel && <span>{fileName}</span>}
    </div>
  )
}
