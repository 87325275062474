import React, { useState } from 'react'
import styles from '../styles.module.css'
import { Table } from 'react-bootstrap'
import { request_statuses, statuses } from '../../../assets/const'
import moment from 'moment'
import Modal from 'react-responsive-modal'
import Button from '../../../components/Button'
import Quill from 'react-quill'
import { useDispatch } from 'react-redux'
import { updateRequestReason } from '../../../actions/common'



export default function RequestsTable ({ data, openRequest, expert, specializations }) {
  

  const renderRow = (request, ind) => {
    const {id, organization, fio, position, category, reason, cert_result, status, current_cert_date } = request
    const statusText = request_statuses.find(stat => stat.value == status)?.name
    const modifyCertDate = current_cert_date ? moment(current_cert_date).format('DD.MM.YYYY') : null
    return (
      <tr key={ind} onClick={openRequest(request)}>
        <td>{organization}</td>
        <td>{fio}</td>
        <td>{position}</td>
        <td>{category}</td>
        <td>{modifyCertDate}</td>
        <td>{category}</td>
        <td>{cert_result}</td>
        <td onClick={e => e.stopPropagation()}>
          {statusText}
          {expert && status == 7 && <ReasonModal reqId={id} value={reason}/>}
        </td>
      </tr>
    )
  }

  

  return (
    <>
      {
        data?.length
          ? <div className={styles.tableContainer}>
              <h3>Список заявлений:</h3>
              <div className={styles.tableBlock}>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Образовательная организация</th>
                      <th>ФИО</th>
                      <th>Должность педагогического работника для аттестации</th>
                      <th>Квалификационная категория для аттестации</th>
                      <th>Дата последней аттестации</th>
                      <th>Текущая категория</th>
                      <th>Комментарий</th>
                      <th>Статус портфолио</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(renderRow)}
                  </tbody>
                </Table>
              </div>
            </div>
          : <div className={styles.noData}>Заявления отсутствуют</div>
      }
    </>
  )
}


const ReasonModal = ({ reqId, value }) => {
  
  const dispatch = useDispatch()

  const [modalVisible, setModalVisible] = useState(false)
  const [reason, setReason] = useState(value)
  
  const handleClose = () => {
    setReason(value)
    setModalVisible(false)
  }

  const handleSave = async () => {
    await dispatch(updateRequestReason(reqId, reason))
    setModalVisible(false)
  }

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>Причина</Button>
      <Modal
        open={modalVisible}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        animationDuration={1}
      >
        <Quill
          value={reason}
          onChange={setReason}
          style={{width: '700px'}}
          className={styles.reasonQuill}
        />
        <div className={styles.reasonButtonsBlock}>
          <Button onClick={handleSave}>Сохранить</Button>
          <Button onClick={handleClose}>Отмена</Button>
        </div>
      </Modal>
    </>
  )
}