import React from 'react'
import styles from './Pagination.module.css'
import { Pagination } from '../../../components/Pagination/Pagination'


const PaginationBlock = ({
    handleSetActivePage,
    activePage,
    count
}) => {
  return (
    <div className={styles.pagination}>
      <Pagination pageCount={count} numberPage={activePage} onPageChange={e => handleSetActivePage(e.selected)}/>
    </div>
  )
}

export default PaginationBlock