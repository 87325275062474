import Routes from "./routing";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers";
import { NotificationContainer } from 'react-notifications'
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import moment from "moment";


import 'react-notifications/lib/notifications.css'
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-modal/styles.css';

moment.locale('ru', {
  months: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split('_'),
  weekdaysMin: 'Пн_Вт_Ср_Чт_Пт_Сб_Вс'.split('_'),
});


const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

const persistor = persistStore(store);


function App() {
  return (
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Routes />
          <NotificationContainer />
        </Provider>
      </PersistGate>
  );
}

export default App;
