import * as types from "../actions/types";



const initialState = {
  loading: false,
};


export default function expert (state = initialState, action) {
  switch (action.type) {
    case types.SIGNED_OUT:
      return initialState;
    case types.SET_EXPERT_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case types.SET_EXPERT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}