import * as types from "../actions/types";



const initialState = {
  organizations: [],
  photo: null,
  userInfo: {},
  loading: false
};


export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_COMPLETED:
      return {
        ...state,
        ...action.user,
      };
    case types.SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };
    case types.SET_AUTH_USER_PHOTO:
      return {
        ...state,
        photo: action.payload
      };
    case types.SET_AUTH_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case types.SIGNED_OUT:
      return {
        ...initialState
      };
    case types.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_COMISSION_DATES:
      return {
        ...state,
        comission: action.payload,
      };
    default:
      return state;
  }
}