import storage from 'redux-persist/es/storage';
import { persistCombineReducers } from 'redux-persist';

import auth from './auth';
import teacher from './teacher'
import common from './common'
import expert from './expert'
import bank from './bank'
import reports from './reports'

const storageConfig = { whitelist: 'auth', key: 'certification_storage', storage };

export default persistCombineReducers(storageConfig, {
  auth,
  teacher,
  expert,
  common,
  bank,
  reports
});