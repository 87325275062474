import * as types from "../actions/types";



const initialState = {
    formLoading: false,
    loading: false
};


export default function teacher (state = initialState, action) {
    switch (action.type) {
      case types.SET_TEACHER_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case types.SET_TEACHER_FORM_LOADING:
        return {
          ...state,
          formLoading: action.payload,
        };
      default:
        return state;
    }
  }