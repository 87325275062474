import React, { useState } from 'react'
import FileLabel from '../FileLabel/FileLabel'
import { AddFileModal } from '../AddFileModal'
import { Table } from 'react-bootstrap'
import styles from './styles.module.scss'
import { IoIosClose } from 'react-icons/io'
import Button from '../Button'
import { AiOutlineCheck } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { acceptAward, toggleAcceptAward } from '../../actions/common'
import Loader from '../Loader'
import { MutatingDots, RotatingLines } from 'react-loader-spinner'
import { BACKEND_DOMAIN } from '../../assets/const'
import { createNotification } from '../Notifications/Notifications'



export default function AwardsBlock ({files, onAddFile, onDeleteFile, disabled, expertsMode, statusCanChanged}) {
  
  return (
    <div className={styles.wrapper}>
      <Table>
        <thead>
          <tr>
            <th>Год получения</th>
            <th>Название</th>
            <th>Файл</th>
            {(!disabled || expertsMode) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {files?.map((file, index) =>
            <AwardRow
              key={index}
              index={index}
              fileObject={file}
              onDeleteFile={onDeleteFile}
              expertsMode={expertsMode}
              disabled={disabled}
              statusCanChanged={statusCanChanged}
            />
          )}
          </tbody>
      </Table>
      <AddFileModal
        entity_type='cert_request_award'
        onAddFile={onAddFile}
        disabled={disabled || expertsMode}
        awardsMode
      />
    </div>
  )
}


const AwardRow = ({index, fileObject, onDeleteFile, disabled, expertsMode, statusCanChanged}) => {
  
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const toggleAcceptAwardHandler = (awardId) => async () => {
    if (!statusCanChanged) return createNotification('error', 'Невозможно изменить статус, т.к. решение по заявке уже принято')
    setLoading(true)
    const status = fileObject.status === 0 ? 1 : 0
    await dispatch(toggleAcceptAward(awardId, status))
    setLoading(false)
  }


  const {user_filename, year, filename, filepath, name, file, awards_id, status} = fileObject
  const fileName = awards_id ? filename : file.name
  const awardName = awards_id ? name : user_filename
  
  const fileLink = awards_id && `${BACKEND_DOMAIN||''}/${filepath}/${filename}`
  

  return (
    <tr key={index}>
      <td>{year}</td>
      <td>{awardName}</td>
      <td>
        {fileLink
          ? <a href={fileLink} target='_blank' rel='noopener noreferrer'>{fileName}</a>
          : fileName 
        }
      </td>
      {!disabled && !expertsMode && <td width={30}><IoIosClose size={36} color='red' onClick={onDeleteFile('cert_request_award', index)} cursor='pointer'/></td>}
      {expertsMode &&
        <td width={50}>
          {loading
            ? <RotatingLines
                height="20"
                width="20"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                strokeColor='#376fb9'
              />
            : status === 0
              ? <Button onClick={toggleAcceptAwardHandler(awards_id)} style={{padding: '0 .3rem', minWidth: '0px'}}>OK</Button>
              : <AiOutlineCheck onClick={toggleAcceptAwardHandler(awards_id)} title={!!statusCanChanged && 'Отменить подпись'} size={22} color='#10d310' />
          }            
        </td>
      }
    </tr>
  )
}