export const version = '16.09.1'

export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN


export const categories = [
    {value: 'первая', name: 'Первая'},
    {value: 'высшая', name: 'Высшая'}
]

export const MONTH_GENITIVE = [ "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря" ];

export const request_statuses = [
    {value: 0, name: 'Удалено'},
    {value: 1, name: 'Черновик'},
    {value: 2, name: 'Отправлено на рассмотрение в ОО'},
    {value: 3, name: 'Отправлено на рассмотрение экспертной группе при Аттестационной комиссии'},
    {value: 4, name: 'Принято на рассмотрение'},
    {value: 5, name: 'Возвращено на доработку'},
    {value: 6, name: 'Рекомендован'},
    {value: 7, name: 'Не рекомендован'}
]

export const statuses = [
    {value: 0, name: 'Решение не принято'},
    {value: 1, name: 'Возвращено на доработку'},
    {value: 2, name: 'Принято решение'},
    {value: 3, name: 'Решение принято (комиссия ОО)'}
]


export const chapters = [
    { value: 1, name: 'Владение современными образовательными технологиями и методиками, эффективность их применения' },
    { value: 2, name: 'Вклад в повышение качества образования, распространение собственного педагогического опыта' },
    { value: 3, name: 'Результаты и показатели динамики достижений обучающихся' },
    { value: 4, name: 'Награды и поощрения за успехи в профессиональной деятельности' }
]

export const parallels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

export const shared_filesHeaderBody = [
    {
        name: 'Дисциплина',
        value: 'lesson',
        colSpan: 2
    },
    {
        name: 'Название файла',
        value: 'filename',
        colSpan: 2
    },
    {
        name: 'Параллель',
        value: 'parallel',
        colSpan: 1
    },
    {
        name: 'Автор',
        value: 'author',
        colSpan: 2
    },
    {
        name: 'Дата публикации',
        value: 'year',
        colSpan: 2
    },
    {
        name: 'Образовательная организация',
        value: 'organization',
        colSpan: 4
    }
]