import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment'
import styles from '../styles.module.css'
import Loader from '../../../components/Loader';
import { IoMdClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../../actions/teacher';
import { request_statuses, statuses } from '../../../assets/const';


export default function RequestsList({
    requestsList,
    requestClick,
    loading
}) {
  
  const dispatch = useDispatch()
  
  const [isOpen, setIsOpen] = useState(true)
  
  const deleteRequestClick = (id) => (e) => {
    e.stopPropagation()
    dispatch(deleteRequest(id))
  }

  const renderListRow = (request) => {
    const {id, created, position, status} = request
    const createdPretty = moment(created).format('DD.MM.YYYY HH:mm')
    const statusText = request_statuses.find(stat => stat.value === status)?.name
    const draft = status === 1
    return (
      <ListGroup.Item key={id} onClick={requestClick(request)} className={styles.listRow}>
          <div>{createdPretty}</div>
          <div>{position}</div>
          <div>{statusText}</div>
          <div onClick={deleteRequestClick(id)}>
            {draft && <IoMdClose/>}
          </div>
      </ListGroup.Item>
    )
  }

  return (
    <div className={styles.listBlock}>
        <div className={styles.listTitle} onClick={() => setIsOpen(!isOpen)}>Список заявлений:</div>
        {isOpen &&
          <ListGroup variant="flush" className={styles.list}>
              {loading
                ? <ListGroup.Item>
                    <Loader wrapperStyle={{marginTop: '0'}} />
                  </ListGroup.Item>
                : requestsList?.length
                    ? requestsList.map(renderListRow)
                    : <ListGroup.Item className={styles.listRow}>Заявления отсутствуют</ListGroup.Item>
              }
          </ListGroup>
        }
    </div>
  )
}
