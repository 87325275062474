import { MutatingDots } from 'react-loader-spinner'
import styles from './styles.module.css'


 const Loader = ({...props}) => 
    <MutatingDots
        height="100"
        width="100"
        color="#376fb9"
        secondaryColor= '#376fb9'
        radius='12.5'
        ariaLabel="mutating-dots-loading"
        wrapperClass={styles.loader}
        visible={true}
        {...props}
    />

export default Loader